import React from "react"
import { graphql } from "gatsby"
import MDXRoot from "gatsby-plugin-mdx/wrap-root-element"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../../components/seo"

const PrivacyPolicyPage = ({ data }) => {
  const {
    body,
    frontmatter: { title },
  } = data.mdx
  return (
    <>
      <SEO title={title} />
      <div style={{ maxWidth: 1024, margin: `24px auto`, padding: "64px" }}>
        <MDXRoot>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXRoot>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`

export default PrivacyPolicyPage
